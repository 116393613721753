<template>

    
    <div>  

        <!-- Section Path File/Modul -->
        <v-breadcrumbs 
            :items="pathModul"
            divider="-"
            normal
            class="pathModul"
        ></v-breadcrumbs>

        <!-- Section Filtering Input/Select/Button -->
        <v-container class="container-filtering">

            <v-row align="center">

                <!-- Column Select Station -->
                <!-- <v-col
                class="d-flex"
                cols="12"
                sm="6"
                md="4"
                > -->
                <v-col
                class="col-input-filter"
                >
                    <v-select
                    v-on:change="selectValueStation"
                    :items="itemStation"
                    label="Station"
                    ></v-select>
                </v-col>

                <!-- Column Select Calendar -->
                <v-col
                class="col-input-filter"
                >
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      v-model="modalCalendar"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="dateFormat"
                        label="Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                      v-on:change="selectDate"
                      v-model="dateFormat"
                      @input="modalCalendar = false"
                      ></v-date-picker>
                    </v-menu>
                </v-col>

                <!-- Column Generate Button -->
                <v-col
                cols="12"
                sm="6"
                md="4"
                class="col-btn-generateReport"
                >
                    <v-btn
                    class="ma-2 white--text btn-generate primary"
                    solo
                    dense
                    normal
                    @click="GenerateTable"
                    :disabled="valStation == null"
                    >
                      Generate Report
                      <v-icon
                      right
                      dark
                      big
                      >
                          mdi-table-arrow-up
                      </v-icon>
                    </v-btn>

                    <!-- <v-btn
                    color="red"
                    elevation="2"
                    class="btn-download-table primary"
                    @click="exportReport('pdf')"
                    >
                      <v-icon
                      color="white"
                      center
                      dark
                      >
                          mdi-file-pdf-box
                      </v-icon>
                    </v-btn> -->

                    <v-btn
                    color="green"
                    elevation="2"
                    @click="exportReport('excel')"
                    :disabled="valStation == null"
                    >
                      <v-icon
                      color="white"
                      center
                      dark
                      >
                          mdi-microsoft-excel
                      </v-icon>
                    </v-btn>

                </v-col>

            </v-row>

                

        </v-container>
        


        <!-- Section Result Generated Report (TABLE) -->
        <v-container class="container-result-generated form-datatable">

            <!-- Main Report Title/Tab -->
            <v-tabs>
                <v-tab>DAILY</v-tab>
            </v-tabs>

            <!-- Mini Title (Table Title) -->
            <v-subheader
            class="subTitle fontSubTitle-blue"
            >
              Water Quality Status For {{locationStation}} ({{stationID}}) {{dateFormat}}
            </v-subheader>

            <!-- Form Table -->
            <div>
              
                <!-- Table -->
                <v-data-table
                :headers="thead_dailyReport"
                :items="tbody_dailyReport"
                :header-props="{ sortIcon: null }"
                class="elevation-1 tableDailyReport"
                :items-per-page="10"
                no-select-on-click
                fixed-header
                :loading="this.loadingTable"
                :loading-text="this.loadingText"
                >
                <template v-slot:header>
                    <thead class="header-colspan">
                    <tr id="testing" >
                      <th class="th-main" v-for="item in mainTheader" :key="item.category">
                        {{ item.category }}
                      </th>
                    </tr>
                    <tr class="checkbox-header-dailyReport">
                        <th id="th_hub" class="th-hub" v-for="item in checkboxTheader" :key="item.value">
                          <input
                          :value="item.value"
                          type="checkbox" 
                          v-model="arrayChecked" 
                          @change="checkedValue($event)">
                        </th>
                    </tr>
                    </thead>
                </template>

                <!-- <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.time }}</td>
                    <td>
                      <v-chip
                      :color="getColor(item.WQI)"
                      dark
                      :class="getFontColor(item.WQI)"
                      >
                        {{ item.WQI }}
                      </v-chip>
                    </td>
                    <td>{{ item.SI_DO_SAT }}</td>
                    <td>{{ item.DO_CON }} <br> <font color='red'><b>{{ item.DOCON_FLAG }}</b></font></td>
                    <td>{{ item.DO_SAT }} <br> <font color='red'><b>{{ item.DOSAT_FLAG }}</b></font></td>
                    <td>{{ item.SI_BOD }}</td>
                    <td>{{ item.BOD }} <br> <font color='red'><b>{{ item.BOD_FLAG }}</b></font></td>
                    <td>{{ item.SI_COD }}</td>
                    <td>{{ item.COD }} <br> <font color='red'><b>{{ item.COD_FLAG }}</b></font></td>
                    <td>{{ item.SI_NH3N }}</td>
                    <td>{{ item.NH3N }} <br> <font color='red'><b>{{ item.NH3N_FLAG }}</b></font></td>
                    <td>{{ item.SI_TSS }}</td>
                    <td>{{ item.TSS }} <br> <font color='red'><b>{{ item.TSS_FLAG }}</b></font></td>
                    <td>{{ item.SI_pH }}</td>
                    <td>{{ item.pH }} <br> <font color='red'><b>{{ item.pH_FLAG }}</b></font></td>
                  </tr>
                </template> -->


                <template v-slot:[`item.WQI`]="{ item }">
                    <v-chip
                    :color="getColor(item.WQI)"
                    dark
                    :class="getFontColor(item.WQI)"
                    >
                      {{ item.WQI }}
                    </v-chip>
                </template>


                <!-- <template v-for="(item, i) in tbody_dailyReport" v-slot:[items[i].item]="{ item }">
                  <div :key="i">
                    {{item}}
                    <br>
                    <p>Anjing</p>
                  </div>
                    
                </template> -->

                
                <template v-slot:[`item.DO_SAT`]="{ item }">
                    <span v-if="item.DOSAT_FLAG == null">{{ item.DO_SAT }}</span>
                    <br v-if="item.DOSAT_FLAG == null">
                    <font color='red'><b>{{ item.DOSAT_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.DO_CON`]="{ item }">
                    <span v-if="item.DOCON_FLAG == null">{{ item.DO_CON }}</span>
                    <br v-if="item.DOCON_FLAG == null">
                    <font color='red'><b>{{ item.DOCON_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.BOD`]="{ item }">
                    <span v-if="item.BOD_FLAG == null">{{ item.BOD }}</span>
                    <br v-if="item.BOD_FLAG == null">
                    <font color='red'><b>{{ item.BOD_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.COD`]="{ item }">
                    <span v-if="item.COD_FLAG == null">{{ item.COD }}</span>
                    <br v-if="item.COD_FLAG == null">
                    <font color='red'><b>{{ item.COD_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.NH3N`]="{ item }">
                    <span v-if="item.NH3N_FLAG == null">{{ item.NH3N }}</span>
                    <br v-if="item.NH3N_FLAG == null">
                    <font color='red'><b>{{ item.NH3N_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.TSS`]="{ item }">
                    <span v-if="item.TSS_FLAG == null">{{ item.TSS }}</span>
                    <br v-if="item.TSS_FLAG == null">
                    <font color='red'><b>{{ item.TSS_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.pH`]="{ item }">
                    <span v-if="item.pH_FLAG == null">{{ item.pH }}</span>
                    <br v-if="item.pH_FLAG == null">
                    <font color='red'><b>{{ item.pH_FLAG }}</b></font>
                </template>

                <template v-slot:[`item._4_4_DDT`]="{ item }">
                    <span v-if="item._4_4_DDT_FLAG == null">{{ item._4_4_DDT }}</span>
                    <br v-if="item._4_4_DDT_FLAG == null">
                    <font color='red'><b>{{ item._4_4_DDT_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.AG`]="{ item }">
                    <span v-if="item.AG_FLAG == null">{{ item.AG }}</span>
                    <br v-if="item.AG_FLAG == null">
                    <font color='red'><b>{{ item.AG_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.AL`]="{ item }">
                    <span v-if="item.AL_FLAG == null">{{ item.AL }}</span>
                    <br v-if="item.AL_FLAG == null">
                    <font color='red'><b>{{ item.AL_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.ALDRIN_DIELDRIN`]="{ item }">
                    <span v-if="item.ALDRIN_DIELDRIN_FLAG == null">{{ item.ALDRIN_DIELDRIN }}</span>
                    <br v-if="item.ALDRIN_DIELDRIN_FLAG == null">
                    <font color='red'><b>{{ item.ALDRIN_DIELDRIN_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.AS`]="{ item }">
                    <span v-if="item.AS_FLAG == null">{{ item.AS }}</span>
                    <br v-if="item.AS_FLAG == null">
                    <font color='red'><b>{{ item.AS_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.B`]="{ item }">
                    <span v-if="item.B_FLAG == null">{{ item.B }}</span>
                    <br v-if="item.B_FLAG == null">
                    <font color='red'><b>{{ item.B_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.BA`]="{ item }">
                    <span v-if="item.BA_FLAG == null">{{ item.BA }}</span>
                    <br v-if="item.BA_FLAG == null">
                    <font color='red'><b>{{ item.BA_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.BHC`]="{ item }">
                    <span v-if="item.BHC_FLAG == null">{{ item.BHC }}</span>
                    <br v-if="item.BHC_FLAG == null">
                    <font color='red'><b>{{ item.BHC_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.BR`]="{ item }">
                    <span v-if="item.BR_FLAG == null">{{ item.BR }}</span>
                    <br v-if="item.BR_FLAG == null">
                    <font color='red'><b>{{ item.BR_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.CA`]="{ item }">
                    <span v-if="item.CA_FLAG == null">{{ item.CA }}</span>
                    <br v-if="item.CA_FLAG == null">
                    <font color='red'><b>{{ item.CA_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.CCE`]="{ item }">
                    <span v-if="item.CCE_FLAG == null">{{ item.CCE }}</span>
                    <br v-if="item.CCE_FLAG == null">
                    <font color='red'><b>{{ item.CCE_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.CD`]="{ item }">
                    <span v-if="item.CD_FLAG == null">{{ item.CD }}</span>
                    <br v-if="item.CD_FLAG == null">
                    <font color='red'><b>{{ item.CD_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.CDOM`]="{ item }">
                    <span v-if="item.CDOM_FLAG == null">{{ item.CDOM }}</span>
                    <br v-if="item.CDOM_FLAG == null">
                    <font color='red'><b>{{ item.CDOM_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.CHLORDANE`]="{ item }">
                    <span v-if="item.CHLORDANE_FLAG == null">{{ item.CHLORDANE }}</span>
                    <br v-if="item.CHLORDANE_FLAG == null">
                    <font color='red'><b>{{ item.CHLORDANE_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.CHLOROPHYLL_A`]="{ item }">
                    <span v-if="item.CHLOROPHYLL_A_FLAG == null">{{ item.CHLOROPHYLL_A }}</span>
                    <br v-if="item.CHLOROPHYLL_A_FLAG == null">
                    <font color='red'><b>{{ item.CHLOROPHYLL_A_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.CL`]="{ item }">
                    <span v-if="item.CL_FLAG == null">{{ item.CL }}</span>
                    <br v-if="item.CL_FLAG == null">
                    <font color='red'><b>{{ item.CL_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.CL2`]="{ item }">
                    <span v-if="item.CL2_FLAG == null">{{ item.CL2 }}</span>
                    <br v-if="item.CL2_FLAG == null">
                    <font color='red'><b>{{ item.CL2_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.CN`]="{ item }">
                    <span v-if="item.CN_FLAG == null">{{ item.CN }}</span>
                    <br v-if="item.CN_FLAG == null">
                    <font color='red'><b>{{ item.CN_FLAG }}</b></font>
                </template>

                <template v-slot:[`item.CO2`]="{ item }">
                    <span v-if="item.CO2_FLAG == null">{{ item.CO2 }}</span>
                    <br v-if="item.CO2_FLAG == null">
                    <font color='red'><b>{{ item.CO2_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.COLOUR`]="{ item }">
                    <span v-if="item.COLOUR_FLAG == null">{{ item.COLOUR }}</span>
                    <br v-if="item.COLOUR_FLAG == null">
                    <font color='red'><b>{{ item.COLOUR_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.CR`]="{ item }">
                    <span v-if="item.CR_FLAG == null">{{ item.CR }}</span>
                    <br v-if="item.CR_FLAG == null">
                    <font color='red'><b>{{ item.CR_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.CR_III`]="{ item }">
                    <span v-if="item.CR_III_FLAG == null">{{ item.CR_III }}</span>
                    <br v-if="item.CR_III_FLAG == null">
                    <font color='red'><b>{{ item.CR_III_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.CR_IV`]="{ item }">
                    <span v-if="item.CR_IV_FLAG == null">{{ item.CR_IV }}</span>
                    <br v-if="item.CR_IV_FLAG == null">
                    <font color='red'><b>{{ item.CR_IV_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.CU`]="{ item }">
                    <span v-if="item.CU_FLAG == null">{{ item.CU }}</span>
                    <br v-if="item.CU_FLAG == null">
                    <font color='red'><b>{{ item.CU_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.DEPTH`]="{ item }">
                    <span v-if="item.DEPTH_FLAG == null">{{ item.DEPTH }}</span>
                    <br v-if="item.DEPTH_FLAG == null">
                    <font color='red'><b>{{ item.DEPTH_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.DOC`]="{ item }">
                    <span v-if="item.DOC_FLAG == null">{{ item.DOC }}</span>
                    <br v-if="item.DOC_FLAG == null">
                    <font color='red'><b>{{ item.DOC_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.E_COLI`]="{ item }">
                    <span v-if="item.E_COLI_FLAG == null">{{ item.E_COLI }}</span>
                    <br v-if="item.E_COLI_FLAG == null">
                    <font color='red'><b>{{ item.E_COLI_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.EC`]="{ item }">
                    <span v-if="item.EC_FLAG == null">{{ item.EC }}</span>
                    <br v-if="item.EC_FLAG == null">
                    <font color='red'><b>{{ item.EC_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.ENDOSULFAN`]="{ item }">
                    <span v-if="item.ENDOSULFAN_FLAG == null">{{ item.ENDOSULFAN }}</span>
                    <br v-if="item.ENDOSULFAN_FLAG == null">
                    <font color='red'><b>{{ item.ENDOSULFAN_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.F`]="{ item }">
                    <span v-if="item.F_FLAG == null">{{ item.F }}</span>
                    <br v-if="item.F_FLAG == null">
                    <font color='red'><b>{{ item.F_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.FC`]="{ item }">
                    <span v-if="item.FC_FLAG == null">{{ item.FC }}</span>
                    <br v-if="item.FC_FLAG == null">
                    <font color='red'><b>{{ item.FC_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.FDOM`]="{ item }">
                    <span v-if="item.FDOM_FLAG == null">{{ item.FDOM }}</span>
                    <br v-if="item.FDOM_FLAG == null">
                    <font color='red'><b>{{ item.FDOM_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.FE`]="{ item }">
                    <span v-if="item.FE_FLAG == null">{{ item.FE }}</span>
                    <br v-if="item.FE_FLAG == null">
                    <font color='red'><b>{{ item.FE_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.FLOWRATE`]="{ item }">
                    <span v-if="item.FLOWRATE_FLAG == null">{{ item.FLOWRATE }}</span>
                    <br v-if="item.FLOWRATE_FLAG == null">
                    <font color='red'><b>{{ item.FLOWRATE_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.GROSS_ALPHA`]="{ item }">
                    <span v-if="item.GROSS_ALPHA_FLAG == null">{{ item.GROSS_ALPHA }}</span>
                    <br v-if="item.GROSS_ALPHA_FLAG == null">
                    <font color='red'><b>{{ item.GROSS_ALPHA_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.GROSS_BETA`]="{ item }">
                    <span v-if="item.GROSS_BETA_FLAG == null">{{ item.GROSS_BETA }}</span>
                    <br v-if="item.GROSS_BETA_FLAG == null">
                    <font color='red'><b>{{ item.GROSS_BETA_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.HARDNESS`]="{ item }">
                    <span v-if="item.HARDNESS_FLAG == null">{{ item.HARDNESS }}</span>
                    <br v-if="item.HARDNESS_FLAG == null">
                    <font color='red'><b>{{ item.HARDNESS_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.HEDONAL_2_4_D`]="{ item }">
                    <span v-if="item.HEDONAL_2_4_D_FLAG == null">{{ item.HEDONAL_2_4_D }}</span>
                    <br v-if="item.HEDONAL_2_4_D_FLAG == null">
                    <font color='red'><b>{{ item.HEDONAL_2_4_D_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.HEPTACHLOR_EPOXIDE`]="{ item }">
                    <span v-if="item.HEPTACHLOR_EPOXIDE_FLAG == null">{{ item.HEPTACHLOR_EPOXIDE }}</span>
                    <br v-if="item.HEPTACHLOR_EPOXIDE_FLAG == null">
                    <font color='red'><b>{{ item.HEPTACHLOR_EPOXIDE_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.HG`]="{ item }">
                    <span v-if="item.HG_FLAG == null">{{ item.HG }}</span>
                    <br v-if="item.HG_FLAG == null">
                    <font color='red'><b>{{ item.HG_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.K`]="{ item }">
                    <span v-if="item.K_FLAG == null">{{ item.K }}</span>
                    <br v-if="item.K_FLAG == null">
                    <font color='red'><b>{{ item.K_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.LINDANE`]="{ item }">
                    <span v-if="item.LINDANE_FLAG == null">{{ item.LINDANE }}</span>
                    <br v-if="item.LINDANE_FLAG == null">
                    <font color='red'><b>{{ item.LINDANE_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.MBAS`]="{ item }">
                    <span v-if="item.MBAS_FLAG == null">{{ item.MBAS }}</span>
                    <br v-if="item.MBAS_FLAG == null">
                    <font color='red'><b>{{ item.MBAS_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.MG`]="{ item }">
                    <span v-if="item.MG_FLAG == null">{{ item.MG }}</span>
                    <br v-if="item.MG_FLAG == null">
                    <font color='red'><b>{{ item.MG_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.MN`]="{ item }">
                    <span v-if="item.MN_FLAG == null">{{ item.MN }}</span>
                    <br v-if="item.MN_FLAG == null">
                    <font color='red'><b>{{ item.MN_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.NA`]="{ item }">
                    <span v-if="item.NA_FLAG == null">{{ item.NA }}</span>
                    <br v-if="item.NA_FLAG == null">
                    <font color='red'><b>{{ item.NA_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.NH4`]="{ item }">
                    <span v-if="item.NH4_FLAG == null">{{ item.NH4 }}</span>
                    <br v-if="item.NH4_FLAG == null">
                    <font color='red'><b>{{ item.NH4_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.NI`]="{ item }">
                    <span v-if="item.NI_FLAG == null">{{ item.NI }}</span>
                    <br v-if="item.NI_FLAG == null">
                    <font color='red'><b>{{ item.NI_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.NO2`]="{ item }">
                    <span v-if="item.NO2_FLAG == null">{{ item.NO2 }}</span>
                    <br v-if="item.NO2_FLAG == null">
                    <font color='red'><b>{{ item.NO2_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.NO3`]="{ item }">
                    <span v-if="item.NO3_FLAG == null">{{ item.NO3 }}</span>
                    <br v-if="item.NO3_FLAG == null">
                    <font color='red'><b>{{ item.NO3_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.OG_EDIBLE`]="{ item }">
                    <span v-if="item.OG_EDIBLE_FLAG == null">{{ item.OG_EDIBLE }}</span>
                    <br v-if="item.OG_EDIBLE_FLAG == null">
                    <font color='red'><b>{{ item.OG_EDIBLE_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.OG_MINERAL`]="{ item }">
                    <span v-if="item.OG_MINERAL_FLAG == null">{{ item.OG_MINERAL }}</span>
                    <br v-if="item.OG_MINERAL_FLAG == null">
                    <font color='red'><b>{{ item.OG_MINERAL_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.P`]="{ item }">
                    <span v-if="item.P_FLAG == null">{{ item.P }}</span>
                    <br v-if="item.P_FLAG == null">
                    <font color='red'><b>{{ item.P_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.PARAQUAT`]="{ item }">
                    <span v-if="item.PARAQUAT_FLAG == null">{{ item.PARAQUAT }}</span>
                    <br v-if="item.PARAQUAT_FLAG == null">
                    <font color='red'><b>{{ item.PARAQUAT_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.PB`]="{ item }">
                    <span v-if="item.PB_FLAG == null">{{ item.PB }}</span>
                    <br v-if="item.PB_FLAG == null">
                    <font color='red'><b>{{ item.PB_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.PCB`]="{ item }">
                    <span v-if="item.PCB_FLAG == null">{{ item.PCB }}</span>
                    <br v-if="item.PCB_FLAG == null">
                    <font color='red'><b>{{ item.PCB_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.PHENOL`]="{ item }">
                    <span v-if="item.PHENOL_FLAG == null">{{ item.PHENOL }}</span>
                    <br v-if="item.PHENOL_FLAG == null">
                    <font color='red'><b>{{ item.PHENOL_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.PO4_3`]="{ item }">
                    <span v-if="item.PO4_3_FLAG == null">{{ item.PO4_3 }}</span>
                    <br v-if="item.PO4_3_FLAG == null">
                    <font color='red'><b>{{ item.PO4_3_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.RA226`]="{ item }">
                    <span v-if="item.RA226_FLAG == null">{{ item.RA226 }}</span>
                    <br v-if="item.RA226_FLAG == null">
                    <font color='red'><b>{{ item.RA226_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.S`]="{ item }">
                    <span v-if="item.S_FLAG == null">{{ item.S }}</span>
                    <br v-if="item.S_FLAG == null">
                    <font color='red'><b>{{ item.S_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.SALINITY`]="{ item }">
                    <span v-if="item.SALINITY_FLAG == null">{{ item.SALINITY }}</span>
                    <br v-if="item.SALINITY_FLAG == null">
                    <font color='red'><b>{{ item.SALINITY_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.SE`]="{ item }">
                    <span v-if="item.SE_FLAG == null">{{ item.SE }}</span>
                    <br v-if="item.SE_FLAG == null">
                    <font color='red'><b>{{ item.SE_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.SILICA`]="{ item }">
                    <span v-if="item.SILICA_FLAG == null">{{ item.SILICA }}</span>
                    <br v-if="item.SILICA_FLAG == null">
                    <font color='red'><b>{{ item.SILICA_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.SILVEX_2_4_5_TP`]="{ item }">
                    <span v-if="item.SILVEX_2_4_5_TP_FLAG == null">{{ item.SILVEX_2_4_5_TP }}</span>
                    <br v-if="item.SILVEX_2_4_5_TP_FLAG == null">
                    <font color='red'><b>{{ item.SILVEX_2_4_5_TP_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.SN`]="{ item }">
                    <span v-if="item.SN_FLAG == null">{{ item.SN }}</span>
                    <br v-if="item.SN_FLAG == null">
                    <font color='red'><b>{{ item.SN_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.SO4`]="{ item }">
                    <span v-if="item.SO4_FLAG == null">{{ item.SO4 }}</span>
                    <br v-if="item.SO4_FLAG == null">
                    <font color='red'><b>{{ item.SO4_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.SOLAR_BATTERY`]="{ item }">
                    <span v-if="item.SOLAR_BATTERY_FLAG == null">{{ item.SOLAR_BATTERY }}</span>
                    <br v-if="item.SOLAR_BATTERY_FLAG == null">
                    <font color='red'><b>{{ item.SOLAR_BATTERY_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.SR_90`]="{ item }">
                    <span v-if="item.SR_90_FLAG == null">{{ item.SR_90 }}</span>
                    <br v-if="item.SR_90_FLAG == null">
                    <font color='red'><b>{{ item.SR_90_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.TC`]="{ item }">
                    <span v-if="item.TC_FLAG == null">{{ item.TC }}</span>
                    <br v-if="item.TC_FLAG == null">
                    <font color='red'><b>{{ item.TC_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.TDS`]="{ item }">
                    <span v-if="item.TDS_FLAG == null">{{ item.TDS }}</span>
                    <br v-if="item.TDS_FLAG == null">
                    <font color='red'><b>{{ item.TDS_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.TEMPERATURE`]="{ item }">
                    <span v-if="item.TEMP_FLAG == null">{{ item.TEMPERATURE }}</span>
                    <br v-if="item.TEMP_FLAG == null">
                    <font color='red'><b>{{ item.TEMP_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.TOC`]="{ item }">
                    <span v-if="item.TOC_FLAG == null">{{ item.TOC }}</span>
                    <br v-if="item.TOC_FLAG == null">
                    <font color='red'><b>{{ item.TOC_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.TRIOXONE_2_4_5_T`]="{ item }">
                    <span v-if="item.TRIOXONE_2_4_5_T_FLAG == null">{{ item.TRIOXONE_2_4_5_T }}</span>
                    <br v-if="item.TRIOXONE_2_4_5_T_FLAG == null">
                    <font color='red'><b>{{ item.TRIOXONE_2_4_5_T_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.TURBIDITY`]="{ item }">
                    <span v-if="item.TURBIDITY_FLAG == null">{{ item.TURBIDITY }}</span>
                    <br v-if="item.TURBIDITY_FLAG == null">
                    <font color='red'><b>{{ item.TURBIDITY_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.U`]="{ item }">
                    <span v-if="item.U_FLAG == null">{{ item.U }}</span>
                    <br v-if="item.U_FLAG == null">
                    <font color='red'><b>{{ item.U_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.VELOCITY`]="{ item }">
                    <span v-if="item.VELOCITY_FLAG == null">{{ item.VELOCITY }}</span>
                    <br v-if="item.VELOCITY_FLAG == null">
                    <font color='red'><b>{{ item.VELOCITY_FLAG }}</b></font>
                </template>
                
                <template v-slot:[`item.ZN`]="{ item }">
                    <span v-if="item.ZN_FLAG == null">{{ item.ZN }}</span>
                    <br v-if="item.ZN_FLAG == null">
                    <font color='red'><b>{{ item.ZN_FLAG }}</b></font>
                </template>
                


                <!-- TAMBAHAN PARAM! -->
                <template v-slot:[`item.TRYPHPTOPHAN`]="{ item }">
                    <span v-if="item.TRYPHPTOPHAN_FLAG == null">{{ item.TRYPHPTOPHAN }}</span>
                    <br v-if="item.TRYPHPTOPHAN_FLAG == null">
                    <font color='red'><b>{{ item.TRYPHPTOPHAN_FLAG }}</b></font>
                </template>
                
                

                </v-data-table>

                <!-- Button Generate Chart -->
                <div class="parent-btn-generate-chart">
                <v-btn
                class="ma-2 white--text btn-generate primary"
                solo
                dense
                normal
                @click="generateChart"
                :disabled="valStation == null"
                >
                    Generate Chart
                    <v-icon
                    right
                    dark
                    big
                    >
                    mdi-chart-line
                    </v-icon>
                </v-btn>
                </div>
            </div>

            

        </v-container>



        <!-- Section Result Generated Report (CHART) -->
        <v-container class="container-result-generated form-chart">

            <!-- Chart -->
            <highcharts 
            :constructorType="'stockChart'" 
            class="hc" 
            :options="chartOptions" 
            ref="chart"
            >
            </highcharts>

            <!-- Button Back to Table -->
            <div class="parent-btn-generate-chart">
              <v-btn
              class="ma-2 white--text btn-generate primary"
              solo
              dense
              normal
              @click="backToTable"
              >
                Back
                <v-icon
                right
                dark
                big
                >
                  mdi-arrow-left-top-bold
                </v-icon>
              </v-btn>
            </div>

        </v-container>

        <template>
          <v-footer padless>
            <v-col
              class="text-center footer-report"
              cols="12"
            >

              <span class="one-text-footer mx-5">
                    <span class="red--text">C</span>
                    <span class="blue--text">=CALIBRATION</span>
                </span>
                <span class="one-text-footer mx-5">
                    <span class="red--text">M</span>
                    <span class="blue--text">=MAINTENANCE</span>
                </span>
                <span class="one-text-footer mx-5 dontBreak">
                    <span class="red--text">DOR</span>
                    <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
                </span><br>
                <span class="one-text-footer mx-5">
                    <span class="red--text">OUTL</span>
                    <span class="blue--text">=OUTLIER</span>
                </span>
                <span class="one-text-footer mx-5 dontBreak">
                    <span class="red--text">PRBC</span>
                    <span class="blue--text">=PARAMETER RELATIONSHIP(BOD≥COD)</span>
                </span>
                <span class="one-text-footer mx-5">
                    <span>NA</span>
                    <span class="blue--text">=DATA NOT AVAILABLE</span>
                </span>

              <!-- <span class="one-text-footer mx-5">
                <span class="red--text">C</span>
                <span class="blue--text">=CALIBRATION</span>
              </span>
              <span class="one-text-footer mx-5">
                <span class="red--text">M</span>
                <span class="blue--text">=MAINTENANCE</span>
              </span>
              <span class="one-text-footer mx-5">
                <span class="red--text">DOR</span>
                <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
              </span>
              <span class="one-text-footer mx-5">
                <span class="red--text">OUTL</span>
                <span class="blue--text">=OUTLIER</span>
              </span>
              <span class="one-text-footer mx-5">
                <span>NA</span>
                <span class="blue--text">=DATA NOT AVAILABLE</span>
              </span> -->

            </v-col>
          </v-footer>
        </template>

    </div>

    

</template>



<script>

import axios from 'axios';
import Papa from "papaparse";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// import renderPDF from 'chrome-headless-render-pdf';


export default {

    

    data: () => ({


        GlobalDataDaily : [],
        DataRenderingChart : [],


        pathModul: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
                // href: '/Mapviewer',
            },
            {
                text: 'Report - Daily',
                disabled: true,
                href: '/Report/DailyReport',
            },
        ],
        itemStation: [],
        dateFormat: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modalCalendar: false,
        menu2: false,

        // Variable/Object for value filtering
        valStation : null,
        valDate : null,

        // Title - Subtitle After Generate Data
        stationID: null,
        locationStation: null,

        // DataTable
        loadingTable: false,
        loadingText: "",

        // Header Table
        mainTheader: [],
        checkboxTheader : [],
        thead_dailyReport: [],
        mainTime: {category: "TIME"},
        subTime : {
          text: 'TIME',
          sortable: false,
          value: 'time',
          divider: true,
          align: 'center',
        },
        sortArraySubHeader: [],

        // Variable/Object for collection of checked parameter from header table
        arrayChecked: [],

        // Body Table
        tbody_dailyReport: [],

        exportTableParam: [],


        // Highchart formating generated
        chartOptions: {
          chart: {
            height: 600
            // type: 'spline'
          },
          title: {
            text: 'Water Quality Status'
          },
          subtitle: {
            text: ''
          },
          // tooltip: {
          //     shared: false,
          // },
          yAxis: {
            // labels: {
            //   format: '{value}%'
            // },
            // max: 100,
            // plotBands: [{
            //   label: "lower siall",
            //   color: 'rgba(0, 0, 255, 0.05)',
            //   from: 0,
            //   to: 25
            // },
            // {
            //   label: "mid??",
            //   color: 'rgba(0, 255, 0, 0.05)',
            //   from: 25,
            //   to: 75
            // },
            // {
            //   label: "high siott!",
            //   color: 'rgba(250, 0, 0, 0.05)',
            //   from: 75,
            //   to: 100
            // }],
            plotLines: [],
            // title: {
            //   text: 'Utilization'
            // }
          },
          series: [],
          legend: {
              enabled: true
          },
        },
        
        arrayDataChart: [],

        plotLinesValue: [
          // { param: "WQI", class1: 92.7, class2: 76.5, class3: 51.9, class4: 31.0, class5: 30.9, threshold: null},
          // { param: "DO_CON", class1: 7, class2: 5, class3: 3, class4: 1, class5: 1, threshold: null},
          // { param: "BOD", class1: 0.98, class2: 1, class3: 4, class4: 6, class5: 12, threshold: null},
          // { param: "COD", class1: 9.98, class2: 10, class3: 25, class4: 50, class5: 100, threshold: null},
          // { param: "NH3N", class1: 0.095, class2: 0.1, class3: 0.3, class4: 0.9, class5: 2.7, threshold: null},
          // { param: "TSS", class1: 24.98, class2: 25, class3: 50, class4: 150, class5: 300, threshold: null},
          // { param: "pH", class1: 7, class2: 6, class3: 5, class4: 4.98, threshold: null},
          { param: "WQI", class1: 92.7, class2: 76.5, class3: 51.9, class4: 31.0, class5: 30.9, threshold: null},
          { param: "DO_CON", class1: 7, class2: 5, class3: 3, class4: 1, class5: 1, threshold: null},
          { param: "BOD", class1: 0.98, class2: 1, class3: 4, class4: 6, class5: 12, threshold: null},
          { param: "COD", class1: 9.98, class2: 10, class3: 25, class4: 50, class5: 100, threshold: null},
          { param: "NH3N", class1: 0.098, class2: 0.1, class3: 0.3, class4: 0.9, class5: 2.7, threshold: null},
          { param: "TSS", class1: 24.98, class2: 25, class3: 50, class4: 150, class5: 300, threshold: null},
          { param: "pH", class1: 7, class2: 6, class3: 5, class4: 4.98, threshold: null},
        ],

        objClassLevel: []

        
        
    }),
    methods: {

      // convert12Hours(time) {
      //     var H = +time.substr(0, 2);
      //     var h = H % 12 || 12;
      //     var ampm = (H < 12 || H === 24) ? "AM" : "PM";
      //     time = h + time.substr(2, 3) + ' ' + ampm;
      //     return time;
      // },

      // Anything need to load first refresh
      load(){
        // console.log(this);
        this.getListStation();
      },

      // Generate list dropdown station dynamic
      getListStation(){
        axios.get('https://apis.spatialworks.com.my/sarawak/cms/stations', {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
          .then((response) => {
              // console.log(response.data);
              this.itemStation = [];

              for (let i = 0; i < response.data.length; i++) {
                this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
                
              }
              
          })
          .catch(error => {
              console.log(error);
          })
      },

      // Get Data From Api
      getDataDaily(station,tarikh,arrChecked){
          // this.tbody_dailyReport = [];
          // console.log(station);
          // console.log(tarikh);
          // console.log(arrChecked);

          axios.get('https://apis.spatialworks.com.my/sarawak/cms/daily?startdt='+tarikh+'&stationid='+station, {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
          .then((response) => {
              // console.log(response.data);

              // Globalkan Data Daily Report
              this.DataRenderingChart.push(response.data); 
              for (let i = 0; i < response.data.length; i++) {
                let keyData = Object.keys(response.data[0])
                // console.log(keyData);
                response.data[i].time = this.convert12Hours(response.data[i].time);
                
                for (let x = 0; x < keyData.length; x++) {
                  if (keyData[x].includes("FLAG")) {
                    // console.log(keyData[x]);
                    if (response.data[i][keyData[x]] < 0 || response.data[i][keyData[x]] == "NULL" || response.data[i][keyData[x]] == "null") {
                      response.data[i][keyData[x]] = null;
                      // console.log(response.data[i]);
                      this.GlobalDataDaily.push(response.data);
                    }
                    this.GlobalDataDaily.push(response.data);
                  }
                  else{
                    // if (response.data[i][keyData[x]] < 0 || response.data[i][keyData[x]] == null || response.data[i][keyData[x]] == "NULL" ||response.data[i][keyData[x]] == "null") {
                    //   response.data[i][keyData[x]] = "NA";
                    //   this.GlobalDataDaily.push(response.data);
                    // }
                    if (response.data[i][keyData[x]] == null || response.data[i][keyData[x]] == "NULL" ||response.data[i][keyData[x]] == "null") {
                      response.data[i][keyData[x]] = "NA";
                      this.GlobalDataDaily.push(response.data);
                    }
                    else{
                      
                      if (keyData[x] != "WQI" && keyData[x] != "SI_DO_SAT" && keyData[x] != "SI_BOD" && keyData[x] != "SI_COD" && keyData[x] != "SI_NH3N" && keyData[x] != "SI_TSS" && keyData[x] != "SI_pH") {
                        if (typeof(response.data[i][keyData[x]]) == "number") {
                          response.data[i][keyData[x]] = response.data[i][keyData[x]].toFixed(4);
                        }
                      }

                      
                      // response.data[i][keyData[x]] = response.data[i][keyData[x]].toFixed(4);
                      // console.log( response.data[i][keyData[x]]);
                    }
                    
                    if (response.data[i].WQI < 0 || response.data[i].WQI == null || response.data[i].WQI == "NULL" ||response.data[i].WQI == "null") {
                      response.data[i].WQI = "NA";
                      this.GlobalDataDaily.push(response.data);
                    }
                  }
                  
                  
                  
                }
                
              }


              this.GlobalDataDaily.push(response.data);
              // console.log(this.GlobalDataDaily);

              this.tbody_dailyReport = this.GlobalDataDaily[0];
              // console.log(this.tbody_dailyReport);
              this.loadingTable = false;
              this.loadingText = "";

              // Get data to generate body table and title location table
              for (let i = 0; i < response.data.length; i++) {
                // this.tbody_dailyReport.push(response.data[i]);
                this.locationStation = response.data[0]["LOCATION"];
              }
          })
          .catch(error => {
              console.log(error);
          })
      },

      getParamStation(station){

        axios.get('https://apis.spatialworks.com.my/sarawak/cms/params?stationid='+ station, {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
          .then((response) => {
              // console.log(response.data);

              for(let i in response.data){
                if (response.data[i].value == "_4_4_DDT") {
                  response.data[i].category = "4";
                }
              }

              // Arrange index data based on specific params - Main Header
              let key1 = [
                'category',
              ],
              result1 = response.data.map(o => Object.assign(...key1.map(k => ({ [k]: o[k] }))));
              // console.log(result1);
              let jsonMainHeader = [];
              let jsonMainHeader2 = [];
              let jsonDo = [];
              let jsonBod = [];
              let jsonCod = [];
              let jsonNh3n = [];
              let jsonTss = [];
              let jsonpH = [];
              let jsonLain2 = [];
              

              for (let i = 0; i < result1.length; i++) {
                // console.log(result1[i]);
                if (result1[i].category === "WQI") {
                  jsonMainHeader.push(result1[i]);
                }
                if(result1[i].category === "DO"){
                  jsonDo.push(result1[i]);
                }
                if(result1[i].category === "BOD"){
                  jsonBod.push(result1[i]);
                }
                else if(result1[i].category === "COD"){
                  jsonCod.push(result1[i]);
                }
                else if(result1[i].category === "NH3N"){
                  jsonNh3n.push(result1[i]);
                }
                else if(result1[i].category === "TSS"){
                  jsonTss.push(result1[i]);
                }
                else if(result1[i].category === "pH"){
                  jsonpH.push(result1[i]);
                }
                else if(
                  result1[i].category != "WQI" && result1[i].category != "DO"
                  && result1[i].category != "BOD" && result1[i].category != "COD"
                  && result1[i].category != "NH3N" && result1[i].category != "TSS"
                  && result1[i].category != "pH"
                ){
                  jsonLain2.push(result1[i]);
                }
                
              }

              // console.log(jsonpH);

              // console.log(jsonDo);
              // console.log(jsonLain2);
              if (jsonDo.length > 0) {
                jsonMainHeader.push(jsonDo[0]);
              }
              if (jsonBod.length > 0) {
                jsonMainHeader.push(jsonBod[0]);
              }
              if (jsonCod.length > 0) {
                jsonMainHeader.push(jsonCod[0]);
              }
              if (jsonNh3n.length > 0) {
                jsonMainHeader.push(jsonNh3n[0]);
              }
              if (jsonTss.length > 0) {
                jsonMainHeader.push(jsonTss[0]);
              }
              if (jsonpH.length > 0) {
                jsonMainHeader.push(jsonpH[0]);
              }
              if(jsonLain2.length > 0){
                // console.log("nothing");
                for (let i = 0; i < jsonLain2.length; i++) {
                  jsonMainHeader2.push(jsonLain2[i]);
                }
              }


              for (let i = 0; i < jsonMainHeader2.length; i++) {
                jsonMainHeader.push(jsonMainHeader2[i]);
              }
              
              jsonMainHeader.unshift(this.mainTime); 

              // console.log(jsonMainHeader);
              
              this.mainTheader = jsonMainHeader;

              
              // console.log(response.data);

              let keys2 = [
                'category',
                'text',
                'value',
                'divider',
                'align',
              ],
              result2 = response.data.map(o => Object.assign(...keys2.map(k => ({ [k]: o[k] }))));
              // console.log(result2);

              // let indexDO = null;
              // for(let i in result2){
              //   if (result2[i].value == "_4_4_DDT") {
              //     result2[i].category =
              //   }
              // }

              this.thead_dailyReport = result2
              this.thead_dailyReport.unshift(this.subTime);
              
              for (let i = 0; i < result2.length; i++) {
                this.exportTableParam.push(result2[i].value);
              }

              // console.log(this.thead_dailyReport);
              
              // this.exportTableParam.unshift(this.subTime);
              

              this.checkboxTheader = result2
              // for (let i = 0; i < result2.length; i++) {
              //   this.checkboxTheader = result2.value;
              // }

              // console.log(this.checkboxTheader);
              
              let thmain;
              setTimeout(function(){
                // Fixed Showing Colspan Table Thead Main
                thmain = null;
                thmain = document.querySelectorAll(".th-main");
                // thmain.setAttribute("colspan", 3);
                // console.log(thmain);
                for (let i = 0; i < thmain.length; i++) {
                  // console.log(thmain[i].childNodes[0]);
                  if (thmain[i].childNodes[0].data == " DO "
                  // || thmain[i].childNodes[0].data == " CR "
                  ) {

                    // console.log(jsonDo.length);

                    if (jsonDo.length == 3) {
                      thmain[i].setAttribute("colspan", 3);
                    }
                    else if (jsonDo.length == 2){
                      thmain[i].setAttribute("colspan", 2);
                    }
                    else if (jsonDo.length == 1){
                      thmain[i].setAttribute("colspan", 1);
                    }
                    
                  }
                  else if (thmain[i].childNodes[0].data == " BOD ") {
                    if (jsonBod.length == 2) {
                      thmain[i].setAttribute("colspan", 2);
                    }
                    else if (jsonBod.length == 1){
                      thmain[i].setAttribute("colspan", 1);
                    }
                  }
                  else if (thmain[i].childNodes[0].data == " COD ") {
                    if (jsonCod.length == 2) {
                      thmain[i].setAttribute("colspan", 2);
                    }
                    else if (jsonCod.length == 1){
                      thmain[i].setAttribute("colspan", 1);
                    }
                  }
                  else if (thmain[i].childNodes[0].data == " NH3N ") {
                    if (jsonNh3n.length == 2) {
                      thmain[i].setAttribute("colspan", 2);
                    }
                    else if (jsonNh3n.length == 1){
                      thmain[i].setAttribute("colspan", 1);
                    }
                  }
                  else if (thmain[i].childNodes[0].data == " TSS ") {
                    if (jsonTss.length == 2) {
                      thmain[i].setAttribute("colspan", 2);
                    }
                    else if (jsonTss.length == 1){
                      thmain[i].setAttribute("colspan", 1);
                    }
                  }
                  else if (thmain[i].childNodes[0].data == " pH ") {
                    if (jsonpH.length == 2) {
                      thmain[i].setAttribute("colspan", 2);
                    }
                    else if (jsonpH.length == 1){
                      thmain[i].setAttribute("colspan", 1);
                    }
                  }
                  
                }
                
              }, 1000);


              // let th_hub = document.querySelectorAll("#th_hub");
              // th_hub.classList.add('th-sub');

              
              
          })
          .catch(error => {
              console.log(error);
          })
        
      },

      // Get value from Selected Station
      selectValueStation: function(e) {
          // console.log(e);
          this.valStation = e;
      },

      // Get value from Selected Date
      selectDate: function(e) {
          this.valDate = e;
      },

      // Generate Table
      GenerateTable(){

        this.loadingTable = true;
        this.loadingText = "Data Loading... Please wait";

        // Keluarkan Details SubText
        // this.stationID = this.valStation.slice(0, 5);
        this.stationID = this.valStation.split(' - ')[0];
        // console.log(this.stationID);

        // Unhide checkbox on header
        document.querySelector(".subTitle").style.display = "unset";
        let chk = document.querySelectorAll(".checkbox-header-dailyReport")
        for (let i = 0; i < chk.length; i++) {
          chk[i].style.display = "contents";
        }

        // Clear Body Table first after generate new datatable
        this.GlobalDataDaily = [];
        this.DataRenderingChart = [];
        this.mainTheader = [];
        this.checkboxTheader = [];
        this.thead_dailyReport = [];
        this.tbody_dailyReport = [];
        this.sortArraySubHeader = [];
        this.arrayChecked = [];
        document.querySelector('.v-data-table__wrapper').scrollLeft = 0;

        

        // Generate Thead as Dynamic
        this.getParamStation(this.valStation.split(' - ')[0]);

        // Run/Generate Table from function load data API
        this.getDataDaily(this.valStation.split(' - ')[0],this.dateFormat,this.arrayChecked);
      
        // console.log(this.thead_dailyReport);
        // console.log(this.tbody_dailyReport);
        

        

      },

      // Customize background color for column table for result WQI status
      getColor(wqi) {
        if (wqi > 92.7){
          return '#3486FA'
        }
        else if (wqi > 76.5 && wqi <= 92.7) {
          return '#7AE666'
        }
        else if (wqi >= 50 && wqi <= 76.5) {
          return '#FFFF10'
        }
        else if (wqi >= 31.0 && wqi <= 51.9) {
          return '#FFAB3D'
        }
        else if(wqi >= 1 && wqi <= 30.9){
          return '#FB401D'
        }
        else{
          return 'white'
        }
      },

      // Customize font color for column table for result WQI status
      getFontColor(wqi){
        if (wqi > 92.7){
          return 'white--text'
        }
        else if (wqi > 76.5 && wqi <= 92.7) {
          return 'black--text'
        }
        else if (wqi >= 50 && wqi <= 76.5) {
          return 'black--text'
        }
        else if (wqi >= 31.0 && wqi <= 51.9) {
          return 'white--text'
        }
        else if(wqi >= 1 && wqi <= 30.9){
          return 'white--text'
        }
        else{
          return 'black--text'
        }
      },

      // Export Table into PDF/Excel
      exportReport(e){
        

        // get all data flag
        let keyData = Object.keys(this.tbody_dailyReport[0])
        let keyParamsFlag = [];
        let keyParamsNonFlag = [];
        // console.log(keyData);
        for(let x in keyData){
          if (keyData[x].includes("FLAG")) {
            // console.log(keyData[x]);
            keyParamsFlag.push(keyData[x]);
          }
          else{
            keyParamsNonFlag.push(keyData[x]);
          }
        }

        let keysFlag = keyParamsFlag,
        resultFlag = this.tbody_dailyReport.map(o => Object.assign(...keysFlag.map(k => ({ [k]: o[k] }))));

        // console.log(resultFlag);

        // let keys = [
        //   'WQI',
        //   'SI_DO_SAT'
        // ],
        let keys = this.exportTableParam,
        result = this.tbody_dailyReport.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

        // console.log(result);

        for(let i in resultFlag){
          for(let j in result){
            if (i == j) {
              for(let x in keyParamsFlag){
                for(let y in keyParamsNonFlag){
                  if (resultFlag[i][keyParamsNonFlag[x]+"_FLAG"] != null) {
                    result[j][keyParamsNonFlag[x]] = resultFlag[i][keyParamsNonFlag[x]+"_FLAG"];
                  }
                  else if (resultFlag[i][keyParamsNonFlag[x].split("_")[0]+keyParamsNonFlag[x].split("_")[1]+"_FLAG"] != null) {
                    result[j][keyParamsNonFlag[x]] = resultFlag[i][keyParamsNonFlag[x].split("_")[0]+keyParamsNonFlag[x].split("_")[1]+"_FLAG"];
                  }
                }
              }
            }
          }
        }

        console.log(this.thead_dailyReport);
        console.log(result);

        for(let n in result){
          this.thead_dailyReport
        }

        
        if (e == "pdf") {
          // console.log("exporting datatable to pdf..");

          // pdfmake

          let columnPdf = [];
          let paramOn = [];
          // let columnTest = [];
          // columnTest.push({ text: 'A', style: 'tableHeader'});
          // columnTest.push({ text: 'B', style: 'tableHeader'});
          // console.log(this.thead_dailyReport);

          this.thead_dailyReport.forEach(function(e){
            e["style"] = "tableHeader";
            columnPdf.push({ text: e.text, style: e.style});
            paramOn.push(e.value);
          });
          // console.log(columnPdf);
          // console.log(paramOn);


          let kunci = this.exportTableParam,
          hasil = this.tbody_dailyReport.map(o => Object.assign(...kunci.map(k => ({ [k]: o[k] }))));
          // console.log(hasil);
          
          // console.log(hasil.filter(x=>x.time).map(x=> x.time ));
          // console.log(hasil.filter(x=>x.time).map(x=>  {return {time:x.time}} ));

          let valuePdf = [];
          for (let i = 0; i < columnPdf.length; i++) {
            hasil["style"] = "tableHeader";
            valuePdf.push({ text: hasil[0][paramOn[i]], style: hasil.style});
            // valuePdf.push({ text: hasil[0].WQI, style: hasil.style});
          }

          let docPdf = {
            pageOrientation: 'landscape',
            // pageSize: 'A4',
            content: [
              {
                table: {
                  // headerRows: 1,
                  // widths: ['auto'],
                  body: [
                      columnPdf,
                      valuePdf
                  ]
                }
              }
              
            ],
            pageMargins: [ 5, 5, 5, 5 ],
            // styles: {
            //   header: {
            //     // fontSize: 2,
            //     bold: true
            //   },
            //   anotherStyle: {
            //     italics: true,
            //     alignment: 'right'
            //   }
            // },
            defaultStyle: {
              header: {
                // fontSize: 2,
                bold: true
              },
              fontSize: 5
              // bold: true
            }
            
          };

          // console.log(docPdf);

          pdfMake.createPdf(docPdf).download('Data Table - Daily Report.pdf');

        }
        else if (e == "excel") {
          // console.log("exporting datatable to excel");

          // console.log(this.thead_dailyReport);
          // console.log(result);

          var blob = new Blob([Papa.unparse(this.specialExcel(this.thead_dailyReport,result))], { type: 'text/csv;charset=utf-8;' });

          var link = document.createElement("a");

          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", 'Water Quality Status For ' + this.locationStation + ' (' + this.stationID + ') ' + this.dateFormat +'.csv');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

      },

      // Get value checked from header checkbox
      checkedValue: function(e,value) {
          // console.log(e);
          // console.log(this.arrayChecked);
      },
      

      // Rendering Data Chart
      loadDataChart(data,checked){

        // Clear first chart before generate another new chart
        this.chartOptions.series = [];
        // console.log(data);
        // console.log(checked);
        let thresholdValue = null;

        

        this.chartOptions.yAxis.plotLines = [];

        // Create object data to put in series chart formating 
        if (checked.length == 1) {

          let objPlotClass = [];

          for (let i = 0; i < this.plotLinesValue.length; i++) {
            if (this.plotLinesValue[i].param == checked[0]) {

              if(this.plotLinesValue[i].param == "WQI"){
                objPlotClass.push(
                  {
                    label: {text:'Class I',verticalAlign: 'top'},
                    color: 'blue',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class1,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class II',verticalAlign: 'top'},
                    color: 'green',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class2,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class III',verticalAlign: 'top'},
                    color: 'yellow',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class3,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class IV',verticalAlign: 'top'},
                    color: 'orange',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class4,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class V',verticalAlign: 'top',y: 15},
                    color: 'red',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class5,
                    width: 2,
                    zIndex:4,
                  },
                )
              }
              if(this.plotLinesValue[i].param == "DO_CON"){
                objPlotClass.push(
                  {
                    label: {text:'Class I',verticalAlign: 'top'},
                    color: 'blue',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class1,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class II',verticalAlign: 'top'},
                    color: 'green',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class2,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class III',verticalAlign: 'top'},
                    color: 'yellow',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class3,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class IV',verticalAlign: 'top'},
                    color: 'orange',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class4,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class V',verticalAlign: 'top',y: 15},
                    color: 'red',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class5,
                    width: 2,
                    zIndex:4,
                  },
                )
              }
              else if(this.plotLinesValue[i].param == "BOD" || this.plotLinesValue[i].param == "COD"){
                objPlotClass.push(
                  {
                    label: {text:'Class I',verticalAlign: 'top',y: 15},
                    color: 'blue',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class1,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class II',verticalAlign: 'top'},
                    color: 'green',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class2,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class III',verticalAlign: 'top'},
                    color: 'yellow',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class3,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class IV',verticalAlign: 'top'},
                    color: 'orange',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class4,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class V',verticalAlign: 'top'},
                    color: 'red',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class5,
                    width: 2,
                    zIndex:4,
                  },
                )
              }
              else if(this.plotLinesValue[i].param == "NH3N"){
                objPlotClass.push(
                  {
                    label: {text:'Class I',verticalAlign: 'bottom', y: 15},
                    color: 'blue',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class1,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class II',verticalAlign: 'top'},
                    color: 'green',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class2,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class III',verticalAlign: 'top'},
                    color: 'yellow',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class3,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class IV',verticalAlign: 'top'},
                    color: 'orange',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class4,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class V',verticalAlign: 'top'},
                    color: 'red',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class5,
                    width: 2,
                    zIndex:4,
                  },
                )
              }
              else if(this.plotLinesValue[i].param == "TSS"){
                objPlotClass.push(
                  {
                    label: {text:'Class I', y: 15},
                    color: 'blue',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class1,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class II'},
                    color: 'green',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class2,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class III'},
                    color: 'yellow',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class3,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class IV'},
                    color: 'orange',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class4,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class V'},
                    color: 'red',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class5,
                    width: 2,
                    zIndex:4,
                  },
                )
              }
              else if(this.plotLinesValue[i].param == "pH"){
                objPlotClass.push(
                  {
                    label: {text:'Class I',verticalAlign: 'top'},
                    color: 'blue',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class1,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class II',verticalAlign: 'top'},
                    color: 'green',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class2,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class III',verticalAlign: 'top'},
                    color: 'yellow',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class3,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class IV',verticalAlign: 'top', y: 15},
                    color: 'orange',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class4,
                    width: 2,
                    zIndex:4,
                  },
                )
              }

              

              this.chartOptions.yAxis.plotLines = objPlotClass;
              
              let arrayValue = [];
              for (let k = 0; k < data.length; k++) {
                let keyData = Object.keys(data[0])
                for (let f = 0; f < keyData.length; f++) {
                  if(keyData[f] == checked[0]){
                    arrayValue.push(data[k][keyData[f]])
                  }
                }
              }

              // console.log(arrayValue);
              let maximum = Math.max(...arrayValue);
              // console.log(maximum);

              if (maximum > this.plotLinesValue[i].class1) {
                thresholdValue = this.plotLinesValue[i].class1;
              }
              else if (maximum > this.plotLinesValue[i].class2) {
                thresholdValue = this.plotLinesValue[i].class2;
              }
              else if (maximum > this.plotLinesValue[i].class3) {
                thresholdValue = this.plotLinesValue[i].class3;
              }
              else if (maximum > this.plotLinesValue[i].class4) {
                thresholdValue = this.plotLinesValue[i].class4;
              }
              else if (maximum < this.plotLinesValue[i].class5) {
                thresholdValue = this.plotLinesValue[i].class5;
              }
              else{
                thresholdValue = null;
              }
              


              // for(let z = 0; z < checked.length; z++){
              //   let obj = {
              //     showInLegend: true,
              //     // type: 'scatter',
              //     name: checked[z],
              //     data: [],
              //     threshold: this.plotLinesValue[i].threshold
              //   }
              //   data.forEach(function(res,k){
              //     obj.data.push([res.epochdate,res[checked[z]]]);
              //     console.log(Math.max(res));
              //   });

              //   console.log(obj);
              //   this.chartOptions.series.push(obj);
              // }
            
            }

          }

          // console.log(this.chartOptions.yAxis.plotLines);
        }
        else{
          this.chartOptions.yAxis.plotLines = [];
        }

        for(let z = 0; z < checked.length; z++){
          let obj = {
            showInLegend: true,
            // type: 'scatter',
            name: checked[z],
            data: [],
            threshold: thresholdValue
          }
          data.forEach(function(res,k){
            obj.data.push([res.epochdate,res[checked[z]]]);
          });

          // console.log(obj);
          this.chartOptions.series.push(obj);
        }

        // Subtitle Chart

        console.log(this.chartOptions);
        console.log(checked);
        this.chartOptions.subtitle.text = "Selected Parameters for "+ this.valStation.split(' - ')[1] +" ("+ this.valStation.split(' - ')[0] +")"

      },

      // Generate Data Chart
      generateChart(){

        // Show Container Form Chart
        document.querySelector(".form-datatable").style.display = "none";
        document.querySelector(".form-chart").style.display = "block";

        let arrStringChecked = [];
        for (let i = 0; i < this.arrayChecked.length; i++) {
          arrStringChecked.push(this.arrayChecked[i]);
        }

        // console.log(arrStringChecked);

        // console.log(this.DataRenderingChart);

        // Load data Chart and Generate Chart from function load chart
        for (let i = 0; i < this.DataRenderingChart[0].length; i++) {
          let keyData = Object.keys(this.DataRenderingChart[0][0])
          // console.log(keyData);
          // response.data[i].time = this.convert12Hours(response.data[i].time);
          for (let x = 0; x < keyData.length; x++) {
            // if (keyData[x].includes("FLAG")) {
            //   // console.log(keyData[x]);
            //   // if (this.DataRenderingChart[0][i][keyData[x]] == null || this.DataRenderingChart[0][i][keyData[x]] == "NULL" || this.DataRenderingChart[0][i][keyData[x]] == "null") {
            //   //   this.DataRenderingChart[0][i][keyData[x]] = null;
            //   // }
            //   console.log("abaikan");
            // }
            if (keyData[x].includes("FLAG") == false){

              if (this.DataRenderingChart[0][i][keyData[x]] == 'NA') {
                this.DataRenderingChart[0][i][keyData[x]] = null;
              }
              else{

                if (keyData[x] != "time") {
                  if (keyData[x] != "WQI" && keyData[x] != "SI_DO_SAT" && keyData[x] != "SI_BOD" && keyData[x] != "SI_COD" && keyData[x] != "SI_NH3N" && keyData[x] != "SI_TSS" && keyData[x] != "SI_pH") {
                    if (typeof(this.DataRenderingChart[0][i][keyData[x]]) == "string") {
                      this.DataRenderingChart[0][i][keyData[x]] = parseFloat(this.DataRenderingChart[0][i][keyData[x]]);
                    }
                  }
                }

              }

              if (this.DataRenderingChart[0][i].WQI == "NA" ) {
                this.DataRenderingChart[0][i].WQI = null;
              }

              // if (this.DataRenderingChart[0][i].time) {
              //   this.DataRenderingChart[0][i].time = this.convert24Hours(this.DataRenderingChart[0][i].time);
              // }


            }
          }
          
        }

        // for (let i = 0; i < this.DataRenderingChart[0].length; i++) {
        //   // console.log(this.DataRenderingChart[0][i]);
        //   if (this.DataRenderingChart[0][i].WQI === "NA") {
        //     // console.log(this.DataRenderingChart[0][i].WQI);
        //     this.DataRenderingChart[0][i].WQI = null;
        //   }
        // }
        this.loadDataChart(this.DataRenderingChart[0],arrStringChecked)

      },

      // Back to Table Form from Chart Form
      backToTable(){

        // Show Container Form Table
        document.querySelector(".form-datatable").style.display = "block";
        document.querySelector(".form-chart").style.display = "none";

        // for (let i = 0; i < this.DataRenderingChart[0].length; i++) {
        //   // console.log(this.DataRenderingChart[0][i]);
        //   if (this.DataRenderingChart[0][i].WQI === null) {
        //     // console.log(this.DataRenderingChart[0][i].WQI);
        //     this.DataRenderingChart[0][i].WQI = "NA";
        //   }
        // }
        for (let i = 0; i < this.DataRenderingChart[0].length; i++) {
          let keyData = Object.keys(this.DataRenderingChart[0][0])
          // console.log(keyData);

          
          for (let x = 0; x < keyData.length; x++) {
            // if (keyData[x].includes("FLAG")) {
            //   // console.log(keyData[x]);
            //   // if (this.DataRenderingChart[0][i][keyData[x]] == null || this.DataRenderingChart[0][i][keyData[x]] == "NULL" || this.DataRenderingChart[0][i][keyData[x]] == "null") {
            //   //   this.DataRenderingChart[0][i][keyData[x]] = null;
            //   // }
            //   console.log("abaikan");
            // }
            if (keyData[x].includes("FLAG") == false){
              if (this.DataRenderingChart[0][i][keyData[x]] == null) {
                this.DataRenderingChart[0][i][keyData[x]] = 'NA';
              }
              else{
                // console.log(keyData[x]);
                if (keyData[x] != "WQI" && keyData[x] != "SI_DO_SAT" && keyData[x] != "SI_BOD" && keyData[x] != "SI_COD" && keyData[x] != "SI_NH3N" && keyData[x] != "SI_TSS" && keyData[x] != "SI_pH") {
                  if (typeof(this.DataRenderingChart[0][i][keyData[x]]) == "number") {
                    this.DataRenderingChart[0][i][keyData[x]] = this.DataRenderingChart[0][i][keyData[x]].toFixed(4);
                  }
                }
                
              }
              if (this.DataRenderingChart[0][i].WQI == null ) {
                this.DataRenderingChart[0][i].WQI = 'NA';
              }
            }
          }
          
        }
      }
      
    },
    mounted() {
      
      // load anything first
      this.load();
      // this.getParamStation();
    }

}
</script>



<style lang="scss">

    @import '~scss/main';

     /* Section Path File/Modul */
    .pathModul{
      padding-bottom: 0px;
    }

    .pathModul > li:nth-child(3) > a{
      color: black !important;
    }

    /* .v-breadcrumbs__item {
      color: black !important;
    } */

    /* Section Filtering Input/Select/Button */
    .container-filtering{
      padding: 0px;
    }

    /* .col-input-filter{
      padding: 0px 10px;
    } */

    .col-btn-generateReport{
        margin-bottom: 18px;
    }

    .btn-generate{
        margin: 0px !important;
        // background: #4495D1 !important;
    }

    .v-text-field.v-text-field--solo .v-input__control,
    .btn-generate .v-btn .v-btn--contained{
        min-height: 10px;
        margin-top: 10px;
    }

    .btn-download-table{
      margin-right: 8px;
    }

    /* Section Result Generated Table Report */
    .container-result-generated{
      background: white;
      padding: 5px 0px;
      // height: calc(100vh - 250px);
    }

    .th-sub{
      background: white !important;
    }

    .subTitle{
      text-align: center !important;
      background: white;
      // color: #1976D2 !important;
      display: none;
    }

    .header-colspan > tr:nth-child(1) > th{
      text-align: center !important;
      /* border-left:  0.1px solid #e0e0e0; */
      border-right:  0.1px solid white;
      border-left:  0.1px solid white;
      /* background: #607d8b; */
      // background: #4495D1; 
      background-image: linear-gradient(to bottom, #0081BA, #02B3C3) !important;
      color: white !important; 
    }

    .header-colspan > tr:nth-child(2) > th{
      text-align: center !important;
      /* border-left:  0.1px solid #e0e0e0; */
      // border-right:  0.1px solid #e0e0e0;
      /* border-top: 0px solid white !important; */
      // border-bottom: thin solid rgb(255 255 255 / 12%) !important;
      border: 0px solid white;
      color: black !important;
      height: 30px !important;
      padding: 20px !important;
    }

    .v-data-table-header > tr:nth-child(1) > th{
      border: 0px solid white !important;
    }

    .v-data-table-header th {
      white-space: nowrap;
      padding: 5px 16px !important;
    }

    .checkbox-header-dailyReport > th:nth-child(1) > input{
      display: none;
    }

    .checkbox-header-dailyReport{
      display: none;
    }

    .tableDailyReport{
      margin: 10px 0px;
      border: 0.1px solid #e0e0e0;
    }

    // .tableDailyReport > div:nth-child(1) > table > thead:nth-child(3) > tr > th{
    //   background: white !important;
    // }

    .tableDailyReport > .v-data-table__wrapper > table > thead > tr > th{
      border-right: 0.1px solid white !important;
      border-left: 0.1px solid white !important;

    }
    // .tableDailyReport > .v-data-table__wrapper > table > thead:nth-child(1) > tr:nth-child(2) > th{
    //   border-right: 0.1px solid white !important;
    // }

    // .th-sub{
    //   background: #02b2c3 !important;
    //   color: white !important;
    //   border-right: 0.1px solid white !important;
    //   border-left: 0.1px solid white !important;
    // }


    .tableDailyReport > .v-data-table__wrapper > table > thead:nth-child(2) > tr:nth-child(2) > th,
    .tableDailyReport > .v-data-table__wrapper > table > thead:nth-child(3) > tr > th{
      background: #02b2c3 !important;
      color: white !important;
      border-right: 0.1px solid white !important;
      border-left: 0.1px solid white !important;
    }

    .tableDailyReport > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1){
      white-space: nowrap;
    }


    .container-result-generated{
      text-align: center;
    }

    .vChip-text{
      color:black !important;
    }

    .datePickerDiv >>> input {
      border: none;
      background: transparent;
    }

    .v-input--selection-controls__input{
      margin: 0px;
    }

    /* Custom vue default */
    .tableDailyReport > .v-data-table__wrapper {
      overflow-x: auto;
      overflow-y: auto;
      max-height: 480px;
    }

    .v-data-table__empty-wrapper > td{
      text-align: left !important;
    }

    /* Section Result Generated Chart Report */
    .form-chart{
      display: none;
    }

    .highcharts-credits{
      display: none !important;
    }

    /* Footer Report */
    .footer-report{
      background: white;
    }
    
    // .one-text-footer{
    //   margin: 0px 10px;
    // }



    /* Set image coloring for all sub themes: */
    /* background-image: linear-gradient(to bottom, #0081BA, #02B3C3); */

</style>